import styled from 'styled-components/macro';

export const StyledLabelWrapper = styled.div`
  display: flex;
  gap: 4px;

  .not-verified {
    color: ${({ theme }) => theme.brand02};
  }
`;

export const StyledVerifyButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
