import React from 'react';
import LayerGroup from 'ol/layer/Group';

interface SelectedTapisDocumentType {
  dok_id: number;
  [key: string]: any;
  layerGroup: LayerGroup;
}

interface PayloadType {
  openedTapis?: boolean;
  openedGeoproduct?: boolean;
  selectedTapisDocument?: SelectedTapisDocumentType;
  selectedGeoproduct?: object;
  openTapisDocument?: boolean;
  openGeoproduct?: boolean;
}

type PayloadAction = {
  type:
    | 'OPENED_GEOPRODUCT'
    | 'OPENED_TAPIS'
    | 'SELECT_TAPIS_DOCUMENT'
    | 'UNSELECT_TAPIS_DOCUMENT'
    | 'CLOSE_DOCUMENT'
    | 'OPEN_TAPIS'
    | 'OPEN_GEOPRODUCT';
  payload?: PayloadType;
};

type Action = PayloadAction;

export type Dispatch = (action: Action) => void;

type State = {
  openedTapis?: boolean;
  openedGeoproduct?: boolean;
  selectedTapisDocument?: SelectedTapisDocumentType;
  selectedGeoproduct?: object;
  openTapisDocument?: boolean;
  openGeoproduct?: boolean;
};

type OpenedTypeProviderProps = { children: React.ReactNode };

const OpenedTypeContext = React.createContext<State>(undefined!);
const OpenedTypeDispatchContext = React.createContext<Dispatch>(undefined!);

function userReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPENED_GEOPRODUCT': {
      return {
        ...state,
        openedTapis: false,
        openedGeoproduct: true,
      };
    }
    case 'OPENED_TAPIS': {
      return {
        ...state,
        openedTapis: true,
        openedGeoproduct: false,
      };
    }

    case 'SELECT_TAPIS_DOCUMENT': {
      return {
        ...state,
        selectedTapisDocument: action?.payload?.selectedTapisDocument,
      };
    }
    case 'UNSELECT_TAPIS_DOCUMENT': {
      return {
        ...state,
        selectedTapisDocument: undefined,
      };
    }
    case 'OPEN_TAPIS': {
      return {
        ...state,
        openTapisDocument: true,
        openGeoproduct: false,
      };
    }
    case 'OPEN_GEOPRODUCT': {
      return {
        ...state,
        openGeoproduct: true,
        openTapisDocument: false,
      };
    }
    case 'CLOSE_DOCUMENT': {
      return {
        ...state,
        openTapisDocument: false,
        openGeoproduct: false,
      };
    }
  }
}

function OpenedTypeProvider({ children }: OpenedTypeProviderProps) {
  const initialState = {
    openedTapis: true,
    openedGeoproduct: false,
    selectedTapisDocument: undefined,
    selectedGeoproduct: undefined,
    openTapisDocument: false,
    openGeoproduct: false,
  };

  const [state, dispatch] = React.useReducer(userReducer, initialState);

  return (
    <OpenedTypeContext.Provider value={state}>
      <OpenedTypeDispatchContext.Provider value={dispatch}>{children}</OpenedTypeDispatchContext.Provider>
    </OpenedTypeContext.Provider>
  );
}

const useOpenedTypeState = () => React.useContext(OpenedTypeContext);
const useOpenedTypeDispatch = () => React.useContext(OpenedTypeDispatchContext);

export { OpenedTypeProvider, useOpenedTypeDispatch, useOpenedTypeState };
