import React from 'react';
import { ClassName, Disabled } from 'interfaces/shared';
import { StyledTooltip } from './style';

export interface TooltipProps extends Disabled, ClassName {
  color?: string;
  title: string | React.ReactNode;
  children: React.ReactNode;
  overlay?: React.ReactElement | (() => React.ReactElement);
  hack?: boolean;
  style?: any;
}

export const Tooltip = ({ color, title, children, overlay, hack, className, style }: TooltipProps) => {
  const mainApp: any = document.getElementsByClassName('App')[0];

  return (
    <StyledTooltip
      color={color}
      title={title}
      overlay={overlay}
      getPopupContainer={() => mainApp}
      className={className}
      style={style}
    >
      {hack ? <div>{children}</div> : children}
    </StyledTooltip>
  );
};
