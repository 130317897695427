import React, { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'contexts/ThemeContext';
import { Button, Dropdown, Icon, Popover, Tooltip } from 'ui';
import { Menu } from 'antd';
import { useIntl } from 'react-intl';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { OptionsContainer, RightSideHeader, MenuButtons, ProfileMenuContainer, HeaderContainer } from './styles';
import DropdownSidebar from './Components/DropdownSidebar';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useUserState } from 'contexts/UserContext';
import useJwt from 'utils/useJwt';
import { useOpenedTypeDispatch, useOpenedTypeState } from '../../../contexts/OpenedTypeContext';
import { AccessibilityMenu } from '../../AccessibilityMenu/AccessibilityMenu';
import { useMapClickResultsOpening } from '../../../contexts/MapClickResultsOpeningContext';
import { ProposalSubmitContext } from '../../../contexts/ProposalSubmitContext';
import useSessionStorage from '../../../utils/useSessionStorage';
import { HeaderOptionTypes } from 'constants/enums';

interface HeaderProps {
  showHeaderOptions?: boolean;
}

interface ProfileMenuOptions {
  id: number;
  url?: string;
  faBase?: string;
  icon: string;
  label: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  tooltip?: string;
}

const DefaultHeader = ({ showHeaderOptions }: HeaderProps) => {
  let [searchParams] = useSearchParams();

  const geoProductsSidebarOpen = searchParams.get('geoproduct');
  const geoDocumentSidebarOpen = searchParams.get('documents');

  const intl = useIntl();
  const navigate = useNavigate();

  const [visibilityConfig, setVisibilityConfig] = useState({
    [HeaderOptionTypes.ACCESSIBILITY]: false,
    [HeaderOptionTypes.OPTIONS]: false
  });
  const [profileVisible, setProfileVisible] = useState<boolean>(false);
  const [logo, setLogo] = useState('/geo_logo.svg');

  const { setOpen: setIsOpenMapClickResults } = useMapClickResultsOpening();
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const mapRoutes = ['/main', '/geo/tapis', 'predefined-page'];
  const { toggleHandler: toggleProposalSubmitHandler, isOpen: isProposalSumbitOpen } =
    useContext(ProposalSubmitContext);
  const user = useUserState();
  const { isTokenActive } = useJwt();
  const openedTypeDispatch = useOpenedTypeDispatch();
  const { openTapisDocument, openGeoproduct, openedTapis, openedGeoproduct } = useOpenedTypeState();
  const disableAuthentication = window?.runConfig?.disableAuthentication;

  const {
    value: locationValue,
    removeSessionValue,
    setSessionValue,
  } = useSessionStorage<string | null | undefined>('SAVE_LOCATION');

  const { data: uiMenu } = useQueryApiClient({
    request: {
      url: 'api/v1/ui-menu',
      disableOnMount: isTokenActive() && !user.selectedRole,
    },
  });

  const showNotifications = () => user?.userNotifications > 0;
  const showEmbeds = () => user?.userEmbeds > 0;
  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const isPhysical = activeRole?.code === 'authenticated';

  const { data: loginUrl } = useQueryApiClient({
    request: {
      url: 'api/v1/saml2',
    },
  });

  useEffect(() => {
    if (locationValue) {
      navigate(locationValue);
      removeSessionValue();
    }
  }, []);

  useEffect(() => {
    if (!!geoDocumentSidebarOpen && geoDocumentSidebarOpen == 'open' && !openTapisDocument) {
      openedTypeDispatch({ type: 'OPEN_TAPIS' });
    } else if (!!geoProductsSidebarOpen && geoProductsSidebarOpen == 'open' && !openGeoproduct) {
      openedTypeDispatch({ type: 'OPEN_GEOPRODUCT' });
    }

    if (isProposalSumbitOpen) toggleProposalSubmitHandler();
  }, [geoDocumentSidebarOpen, geoProductsSidebarOpen, openedTypeDispatch, openTapisDocument, openGeoproduct]);

  useEffect(() => {
    const getLogoPath = () => {
      if (theme === 'black-on-yellow') {
        setLogo('/geo_logo_yellow.svg');
        return;
      }

      if (theme === 'white-on-black') {
        setLogo('/geo_logo_black.svg');
        return;
      }

      setLogo('/geo_logo.svg');
    };

    getLogoPath();
  }, [theme]);

  const menu2 = <DropdownSidebar
    uiMenu={uiMenu}
    onItemClick={() => handleOpenChange(false, HeaderOptionTypes.OPTIONS)}
  />;

  const profileMenu = () => {
    const menuOptions = isTokenActive()
      ? [
          {
            id: 1,
            icon: 'user',
            faBase: 'fa-regular',
            label: intl.formatMessage({
              id: 'profile.my_invoice',
            }),
            url: '/account',
          },
          {
            id: 2,
            icon: 'cart-shopping',
            faBase: 'fa-regular',
            label: intl.formatMessage({
              id: 'profile.my_orders',
            }),
            url: '/orders',
          },
          {
            id: 3,
            icon: 'share-from-square',
            faBase: 'fa-regular',
            label: intl.formatMessage({
              id: 'profile.my_proposals',
            }),
            url: '/proposals',
            disabledTooltip: intl.formatMessage({
              id: 'profile.my_proposals',
            }),
          },
          showEmbeds()
            ? {
                id: 6,
                icon: 'pen',
                faBase: 'fa-regular',
                label: activeRole?.institutionClassifierId
                  ? intl.formatMessage({
                      id: 'profile.org_user_embeds',
                    })
                  : intl.formatMessage({
                      id: 'profile.user_embeds',
                    }),
                url: '/user-embeds',
              }
            : {
                id: 0,
                icon: '',
                faBase: '',
                label: '',
                url: '',
              },
          showNotifications()
            ? {
                id: 7,
                icon: 'pen-to-square',
                faBase: 'fa-regular',
                label: intl.formatMessage({
                  id: 'profile.notifications',
                }),
                url: '/user-notifications',
                tooltip: intl.formatMessage({ id: 'only_physical_persona_notification' }),
              }
            : {
                id: 0,
                icon: '',
                faBase: '',
                label: '',
                url: '',
              },
          {
            id: 8,
            icon: 'arrow-right-from-bracket',
            faBase: 'fa-regular',
            label: intl.formatMessage({
              id: 'profile.logout',
            }),
            onClick: () => {
              user.logout();
            },
          },
        ]
      : [
          {
            id: 1,
            icon: 'user',
            faBase: 'fa-regular',
            label: intl.formatMessage({
              id: 'profile.login',
            }),
            onClick: () => {
              const params = new URLSearchParams(window.location.search);

              setSessionValue(location.pathname + (params ? '?' + params.toString() : null));
              window.location.replace(loginUrl);
            },
          },
        ];
    return (
      <Menu>
        <ProfileMenuContainer>{menuOptions.map((opt) => renderProfileMenuOption(opt))}</ProfileMenuContainer>
      </Menu>
    );
  };

  const renderProfileMenuOption = (opt: ProfileMenuOptions) => {
    const toDisable = ['share-from-square', 'pen-to-square'];
    let disabled = false;

    if (!isPhysical && toDisable.includes(opt?.icon)) {
      disabled = true;
    }

    const item = (
      <div key={opt.id} className={`profile-menu-option-wrapper ${disabled && 'is-disabled'}`}>
        {opt.url && (
          <Link to={opt.url} className="profile-menu-option">
            {opt.icon && opt.faBase && <Icon faBase={opt.faBase} icon={opt.icon} />}
            {opt.label}
          </Link>
        )}
        {opt.onClick && (
          <div onClick={opt.onClick} className="profile-menu-option">
            {opt.icon && opt.faBase && <Icon faBase={opt.faBase} icon={opt.icon} />}
            {opt.label}
          </div>
        )}
      </div>
    );

    if (disabled) {
      return (
        <Tooltip
          style={{ width: '100px' }}
          title={opt.tooltip ? opt.tooltip : intl.formatMessage({ id: 'only_physical_persona' })}
        >
          {item}
        </Tooltip>
      );
    }

    return item;
  };

  const handleOpenChange = (open: boolean, type: HeaderOptionTypes) => {
    setVisibilityConfig({
      ...visibilityConfig,
      [type]: open
    });
  };

  const parseName = (entry: any) => {
    if (!entry) {
      return;
    }

    if (!entry.institutionName) {
      return intl.formatMessage({ id: 'general.physical_person' });
    }

    return entry.name + (entry.institutionName ? ' - ' + entry.institutionName : '');
  };

  return (
    <>
      <div className="left-side-header">
        <Link to="/main">
          <img className="logo" src={logo} alt="" />
        </Link>
        <div className="random-line" />
        <HeaderContainer>
          <MenuButtons>
            {(mapRoutes.includes(location.pathname) || location.pathname.includes('predefined-page')) && (
              <>
                <Button
                  className={`${openedTapis && 'highlight-button'}`}
                  label={intl.formatMessage({ id: 'navigation.planned_documents' })}
                  href="/main?documents=open"
                  onClick={() => {
                    setIsOpenMapClickResults(false);
                    openedTypeDispatch({ type: 'OPEN_TAPIS' });
                  }}
                />
                <Button
                  className={`${openedGeoproduct && 'highlight-button'}`}
                  label={intl.formatMessage({ id: 'navigation.geoproducts' })}
                  href="/main?geoproduct=open"
                  onClick={() => {
                    setIsOpenMapClickResults(false);
                    openedTypeDispatch({ type: 'OPEN_GEOPRODUCT' });
                  }}
                />
              </>
            )}
          </MenuButtons>
        </HeaderContainer>
      </div>
      {showHeaderOptions && (
        <RightSideHeader>
          <OptionsContainer>
            <Dropdown
              open={visibilityConfig[HeaderOptionTypes.ACCESSIBILITY]}
              menu={() => <AccessibilityMenu setVisible={(open) => handleOpenChange(open, HeaderOptionTypes.ACCESSIBILITY)} />}
              trigger={['click']}
              onOpenChange={(open) => handleOpenChange(open, HeaderOptionTypes.ACCESSIBILITY)}
            >
              <div>
                <Icon faBase="far" icon="glasses" className="header-item" />
                <span>Piekļūstamība</span>
              </div>
            </Dropdown>
          </OptionsContainer>
          <OptionsContainer>
            <Popover
              content={menu2}
              trigger="click"
              open={visibilityConfig[HeaderOptionTypes.OPTIONS]}
              onOpenChange={(open: boolean) => handleOpenChange(open, HeaderOptionTypes.OPTIONS)}
            >
              <div className="cursor">
                <Icon faBase="far" icon="bars" className="header-item" />
                <span>Izvēlne</span>
              </div>
            </Popover>
          </OptionsContainer>
          {isTokenActive() ? (
            <Dropdown
              open={profileVisible}
              menu={profileMenu}
              trigger={['click']}
              onOpenChange={(open) => setProfileVisible(open)}
              placement="bottomRight"
              overlayClassName={'w-225'}
            >
              <div className="user-data">
                <div className="profile-icon">{user?.name?.substring(0, 1)}</div>
                <div>
                  <div className="user-name">
                    {user?.name} {user?.surname}
                  </div>
                  <div className="user-role">{parseName(activeRole)}</div>
                </div>
              </div>
            </Dropdown>
          ) : (
            <Dropdown
              open={profileVisible}
              menu={profileMenu}
              trigger={['click']}
              onOpenChange={(open) => setProfileVisible(open)}
              placement="bottomRight"
              overlayClassName={'w-225'}
              disabled={disableAuthentication}
            >
              <div className="user-data">
                <span>Mana darba vieta</span>
              </div>
            </Dropdown>
          )}
        </RightSideHeader>
      )}
    </>
  );
};

export default DefaultHeader;
