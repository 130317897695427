import React from 'react';
import { ClassName, Disabled, Name } from 'interfaces/shared';
import { StyledCheckbox } from './style';

export interface CheckboxProps extends Disabled, ClassName, Name {
  id?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  defaultChecked?: boolean;
  value?: number | string;
  key?: string | number;
  label?: any;
  indeterminate?: boolean;
}

export const Checkbox = ({
  disabled,
  label,
  className,
  defaultChecked,
  name,
  value,
  checked,
  onChange,
  key,
  indeterminate,
}: CheckboxProps) => {
  return (
    <StyledCheckbox
      indeterminate={indeterminate}
      disabled={disabled}
      className={className}
      defaultChecked={defaultChecked}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      key={key}
    >
      {label}
    </StyledCheckbox>
  );
};
