import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { SidebarDrawer } from 'ui';
import { StyledPage, StyledContent } from 'styles/layout/SidebarMap';
import { useSearchParams } from 'react-router-dom';
import { MapComponent } from 'components/Map/MapComponent';
import { LayerSettings } from 'components/Map/LayerSettings';
import { CoordsComponent } from 'components/Map/CoordsComponent';
import { LayerSwitcher } from 'components/Map/LayerSwitcher';
import { Measure } from 'components/Map/Measure';
import MapSearch from '../../components/Map/MapSearch';
import Sidebars from './Components/Sidebars';
import { useOpenedTypeDispatch, useOpenedTypeState } from '../../contexts/OpenedTypeContext';
import { MapClickResults } from 'components/Map/MapClickResults';
import RightSidebars from './Components/RightSidebars/EmbedsSideBar';
import EmbedCreateButton from '../../components/Map/EmbedCreateButton';
import { useApplyForNotifications } from '../../contexts/ApplyForNotificationsContext';
import MapContext from 'contexts/MapContext';
import useQueryApiClient from 'utils/useQueryApiClient';
import { applyEmbedMapState } from 'utils/embedMapUtils';
import UnauthenticatedModal from '../../components/Modals/UnauthenticatedModal';
import { useNotificationHeader } from '../../contexts/NotificationHeaderContext';
import { usePlannedDocumentsFilterContext } from '../../contexts/PlannedDocumentsFilterContext';
import { useMapClickResultsOpening } from '../../contexts/MapClickResultsOpeningContext';

export interface MeasureProps {
  setEnabledMeasureButton: Function;
  enabledMeasureButton: 'line' | 'poly' | null;
}

const LayoutPage = () => {
  let [searchParams] = useSearchParams();

  const map = useContext(MapContext);
  const { value: applyForNotificationsValue } = useApplyForNotifications();
  const intl = useIntl();
  const dispatch = useOpenedTypeDispatch();
  const states = useOpenedTypeState();
  const { height: notificationHeight } = useNotificationHeader();
  const { setFilterParams } = usePlannedDocumentsFilterContext();
  const { open: isOpenMapClickResults, setOpen: setIsOpenMapClickResults } = useMapClickResultsOpening();

  const [isOpen, setIsOpen] = useState<boolean>(!!searchParams.get('geoproduct'));
  const [isOpenPlannedDocuments, setIsOpenPlannedDocuments] = useState<boolean>(!!searchParams.get('documents'));
  const [isOpenLayerSettings, setIsOpenLayerSettings] = useState<boolean>(false);
  const [isOpenEmbedded, setIsOpenedEmbedded] = useState<boolean>(false);
  const [enabledMeasureButton, setEnabledMeasureButton] = useState<'line' | 'poly' | null>(null);
  const [visibleLayers, setVisibleLayers] = useState<string[] | undefined>(undefined);
  const [openedUserEmbedUuid, setOpenedUserEmbedUuid] = useState<string | null>(null);
  const [isOpenUnauthenticated, setIsOpenUnauthenticated] = useState(false);
  const [showCoordinatesWindow, setShowCoordinatesWindow] = useState(false);

  useEffect(() => {
    if (!!applyForNotificationsValue && applyForNotificationsValue.open && !isOpenMapClickResults) {
      setIsOpenMapClickResults(true);
    }
  }, [applyForNotificationsValue]);

  useEffect(() => {
    if (searchParams.get('documents')) {
      dispatch({ type: 'OPENED_TAPIS' });
    }

    if (searchParams.get('geoproduct')) {
      dispatch({ type: 'OPENED_GEOPRODUCT' });
    }
    // handle link from embeded iframe
    if (searchParams.get('embed_uuid')) {
      setOpenedUserEmbedUuid(searchParams.get('embed_uuid'));
    }
  }, [searchParams]);

  useEffect(() => {
    setIsOpenPlannedDocuments(states.openTapisDocument ? states.openTapisDocument : false);
    setIsOpen(states.openGeoproduct ? states.openGeoproduct : false);
  }, [searchParams, states]);

  const { data: embededDataByUuid } = useQueryApiClient({
    request: {
      url: `api/v1/user-embeds/${openedUserEmbedUuid}/uuid`,
      disableOnMount: !openedUserEmbedUuid,
    },
  });

  useEffect(() => {
    if (map && embededDataByUuid?.data) {
      const embedData = JSON.parse(embededDataByUuid.data);
      const visibleLayers = applyEmbedMapState(map, embedData, dispatch);
      setVisibleLayers(visibleLayers);
    }
  }, [map, embededDataByUuid]);

  return (
    <StyledPage notificationHeight={notificationHeight}>
      <Sidebars
        setIsOpenPlannedDocuments={setIsOpenPlannedDocuments}
        isOpenPlannedDocuments={isOpenPlannedDocuments}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <SidebarDrawer
        title={intl.formatMessage({ id: 'layer_settings.map_layers' })}
        isOpen={isOpenLayerSettings}
        onClose={() => setIsOpenLayerSettings(false)}
      >
        <LayerSettings visibleLayers={visibleLayers} />
      </SidebarDrawer>
      <StyledContent>
        {(!openedUserEmbedUuid || visibleLayers) && (
          <MapComponent>
            <MapSearch show={!(isOpen || isOpenPlannedDocuments)} />

            <CoordsComponent
              setShowCoordinatesWindow={setShowCoordinatesWindow}
              showCoordinatesWindow={showCoordinatesWindow}
              enabledMeasureButton={enabledMeasureButton}
              setEnabledMeasureButton={setEnabledMeasureButton}
            />
            <LayerSwitcher setIsOpenLayerSettings={setIsOpenLayerSettings} visibleLayers={visibleLayers} />
            <Measure enabledMeasureButton={enabledMeasureButton} setEnabledMeasureButton={setEnabledMeasureButton} />
            <EmbedCreateButton setIsOpenedEmbedded={setIsOpenedEmbedded} setShowModal={setIsOpenUnauthenticated} />
          </MapComponent>
        )}
      </StyledContent>
      {!isOpenEmbedded ? (
        <SidebarDrawer
          title={states.openedTapis ? 'Teritorijas izmantošana' : 'Rezultāti'}
          isOpen={isOpenMapClickResults}
          onClose={() => {
            setIsOpenMapClickResults(false);
            setFilterParams({});
          }}
        >
          <MapClickResults
            isShowCoordinatesWindowOpen={showCoordinatesWindow}
            setIsOpenMapClickResults={setIsOpenMapClickResults}
            isOpenMapClickResults={isOpenMapClickResults}
            isOpenPlannedDocuments={isOpenPlannedDocuments}
          />
        </SidebarDrawer>
      ) : null}
      <RightSidebars
        setIsOpenedEmbedded={setIsOpenedEmbedded}
        isOpenEmbedded={isOpenEmbedded}
        setVisibleLayers={setVisibleLayers}
      />
      <UnauthenticatedModal setShowModal={setIsOpenUnauthenticated} showModal={isOpenUnauthenticated} />
    </StyledPage>
  );
};

export default LayoutPage;
