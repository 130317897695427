import styled from 'styled-components/macro';
import { Layout } from 'antd';
import { VARIABLES } from 'styles/globals';

const { Sider } = Layout;

export const StyledSidebarDrawer = styled(Sider)<{ notificationHeight?: number }>`
  background-color: ${({ theme }) => theme.componentsBackground}; !important;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.textColor01};
  min-width: 380px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);

  &.ant-layout-sider{
    background-color: ${({ theme }) => theme.componentsBackground}; !important;
  }

  height: calc(
    100vh - ${VARIABLES.headerHeight} - ${VARIABLES.footerHeight} - ${(props) => props.notificationHeight}px
  );
  overflow: auto;

  &.ant-layout-sider-collapsed {
    .ant-layout-sider-children {
      display: none;
    }

    .sidebar-title {
      display: none;
    }

    .content {
      display: none;
    }
  }

  .sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px;
    background-color: ${({ theme }) => theme.portalBackground};

    .close-icon {
      margin-bottom: auto;
    }

    .portal-icon {
      cursor: pointer;
    }
  }

  .content {
    padding: 26px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    height: calc(100% - 76px);

    & > * {
      border-bottom: 1px solid ${({ theme }) => theme.border};
      padding-bottom: 20px;
      color: ${({ theme }) => theme.textColor01};

    }
  }
`;
