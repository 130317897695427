import React, { type Dispatch, type SetStateAction } from 'react';
import { Button, Modal, Spinner } from 'ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledSendEmailVerificationModalContainer } from './styles';

type SendUserEmailVerificationModalProps = {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  email?: string;
  isLoading: boolean;
};

const SendUserEmailVerificationModal = ({
  modalOpen,
  setModalOpen,
  email,
  isLoading,
}: SendUserEmailVerificationModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      open={modalOpen}
      footer={
        <Button
          type="primary"
          label={intl.formatMessage({
            id: 'general.close',
          })}
          onClick={() => setModalOpen(false)}
        />
      }
      onCancel={() => setModalOpen(false)}
      disableHeader
    >
      <StyledSendEmailVerificationModalContainer>
        <h3 className="title">
          <FormattedMessage id="user_email.verification_modal_title" />
        </h3>
        <Spinner spinning={isLoading}>
          {!isLoading && <FormattedMessage id="user_email.verification_sent" values={{ email }} />}
        </Spinner>
      </StyledSendEmailVerificationModalContainer>
    </Modal>
  );
};

export default SendUserEmailVerificationModal;
