import styled from 'styled-components/macro';

export const ListProposalTextWrapper = styled.div`
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  -webkit-line-clamp: 2;
  max-width: 330px;
  white-space: pre-line;
`;
