import React from 'react';
import { UploadProps } from 'antd';
import { StyledUpload } from './style';
import { Form } from 'antd';

export interface ExtraUploadProps extends UploadProps {
  children: React.ReactNode;
}

export const Upload = ({
  beforeUpload,
  multiple,
  accept,
  fileList,
  children,
  onChange,
  onDownload,
  onPreview,
  onRemove,
  customRequest,
  showUploadList,
  maxCount,
  name,
  disabled,
}: ExtraUploadProps) => {
  return (
    <Form.Item name={name}>
      <StyledUpload
        beforeUpload={beforeUpload}
        multiple={multiple}
        accept={accept}
        fileList={fileList}
        onChange={onChange}
        onDownload={onDownload}
        onPreview={onPreview}
        onRemove={onRemove}
        customRequest={customRequest}
        showUploadList={showUploadList}
        maxCount={maxCount}
        disabled={disabled}
      >
        {children}
      </StyledUpload>
    </Form.Item>
  );
};
