import React from 'react';
import { StyledSidebarDrawer } from './style';
import { Icon, Label } from 'ui';
import { useNotificationHeader } from '../../contexts/NotificationHeaderContext';

export interface SidebarDrawerProps {
  children?: React.ReactNode;
  title: string;
  subtitle?: string | React.ReactNode;
  onClose?: () => void | Function;
  isOpen?: boolean;
}

export const SidebarDrawer = ({ children, title, subtitle, onClose, isOpen }: SidebarDrawerProps) => {
  const { height } = useNotificationHeader();

  return (
    <StyledSidebarDrawer
      notificationHeight={height}
      trigger={null}
      collapsible
      collapsed={!isOpen}
      collapsedWidth={0}
      width={380}
    >
      <div className="sidebar-title">
        <div>
          <Label title label={title} bold />
          {subtitle && (typeof subtitle !== 'string' ? subtitle : <Label className="mt-3" label={subtitle} />)}
        </div>
        <Icon className="close-icon" icon="circle-xmark" faBase="fa-regular" onClick={onClose} />
      </div>
      <div className="content">{children}</div>
    </StyledSidebarDrawer>
  );
};
