import React from 'react';

interface IconPropsType {
  stroke: string;
  fill: string;
}

interface IconsType {
  [key: string]: any;
}

export const icons: IconsType = {
  comment: ({ stroke, fill }: IconPropsType) => ({
    anchor: [0.3, 0.9],
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 3H6C4.93913 3 3.92172 3.42143 3.17157 4.17157C2.42143 4.92172 2 5.93913 2 7V15C2 16.0609 2.42143 17.0783 3.17157 17.8284C3.92172 18.5786 4.93913 19 6 19H7V22.15C6.99866 22.2646 7.02025 22.3784 7.06349 22.4845C7.10674 22.5907 7.17077 22.6871 7.25183 22.7682C7.33288 22.8492 7.42933 22.9133 7.53549 22.9565C7.64164 22.9998 7.75538 23.0213 7.87 23.02C8.07123 23.0205 8.26612 22.9497 8.42 22.82L13 19H18C19.0609 19 20.0783 18.5786 20.8284 17.8284C21.5786 17.0783 22 16.0609 22 15V7C22 5.93913 21.5786 4.92172 20.8284 4.17157C20.0783 3.42143 19.0609 3 18 3ZM20 15C20 15.5304 19.7893 16.0391 19.4142 16.4142C19.0391 16.7893 18.5304 17 18 17H12.28L11.72 17.46L9 19.73V17H6C5.46957 17 4.96086 16.7893 4.58579 16.4142C4.21071 16.0391 4 15.5304 4 15V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H18C18.5304 5 19.0391 5.21071 19.4142 5.58579C19.7893 5.96086 20 6.46957 20 7V15ZM8 8H11L9 14H7L8 8ZM14 8H17L15 14H13L14 8Z" fill="${fill}"/>
    </svg>
    `,
  }),
  marker_outline: ({ stroke, fill }: IconPropsType) => ({
    anchor: [0.52, 0.92],
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.48128 18.3783L12 22L15.5187 18.3783C19.4746 14.3385 19.5 11.8279 19.5 9.70128C19.5 5.44797 16.122 2 11.9928 2C7.86354 2 4.5 5.44797 4.5 9.70128C4.5 11.8279 4.52539 14.3385 8.48128 18.3783ZM12 19.1299L14.0898 16.979C15.9256 15.1042 16.7315 13.7046 17.1099 12.648C17.4851 11.6004 17.5 10.7433 17.5 9.70128C17.5 6.50708 14.9725 4 11.9928 4C9.01797 4 6.5 6.50201 6.5 9.70128C6.5 10.7433 6.51487 11.6004 6.89007 12.648C7.26847 13.7046 8.07437 15.1042 9.91025 16.979L9.91576 16.9846L12 19.1299Z" fill="${fill}"/>
    <path d="M12.0862 8.26437C12.484 8.26437 12.8729 8.38234 13.2037 8.60336C13.5345 8.82439 13.7923 9.13854 13.9446 9.50609C14.0968 9.87365 14.1367 10.2781 14.0591 10.6683C13.9814 11.0585 13.7899 11.4169 13.5086 11.6982C13.2272 11.9795 12.8688 12.1711 12.4786 12.2487C12.0884 12.3263 11.684 12.2865 11.3164 12.1342C10.9489 11.982 10.6347 11.7242 10.4137 11.3934C10.1927 11.0626 10.0747 10.6737 10.0747 10.2759C10.0747 9.74238 10.2866 9.23075 10.6639 8.85352C11.0411 8.47629 11.5527 8.26437 12.0862 8.26437Z" fill="${fill}"/>
    </svg>
    `,
  }),
  marker: ({ stroke, fill }: IconPropsType) => ({
    anchor: [0.52, 0.92],
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.48128 18.3783L12 22L15.5187 18.3783C19.4746 14.3385 19.5 11.8279 19.5 9.70128C19.5 5.44797 16.122 2 11.9928 2C7.86354 2 4.5 5.44797 4.5 9.70128C4.5 11.8279 4.52539 14.3385 8.48128 18.3783ZM13.2037 8.60336C12.8729 8.38234 12.484 8.26437 12.0862 8.26437C11.5527 8.26437 11.0411 8.47629 10.6639 8.85352C10.2866 9.23075 10.0747 9.74238 10.0747 10.2759C10.0747 10.6737 10.1927 11.0626 10.4137 11.3934C10.6347 11.7242 10.9489 11.982 11.3164 12.1342C11.684 12.2865 12.0884 12.3263 12.4786 12.2487C12.8688 12.1711 13.2272 11.9795 13.5085 11.6982C13.7899 11.4169 13.9814 11.0585 14.0591 10.6683C14.1367 10.2781 14.0968 9.87365 13.9446 9.50609C13.7923 9.13854 13.5345 8.82439 13.2037 8.60336Z" fill="${fill}"/>
    </svg>
    `,
  }),
};
