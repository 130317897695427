import React, { Dispatch, SetStateAction, useEffect, useContext, useState, useMemo } from 'react';
import { SidebarDrawer } from 'ui';
import { useIntl } from 'react-intl';
import { DetailedPlannedDocument } from './DetailedPlannedDocument';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import ApplyingForNotification from './ApplyingForNotification';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ProposalSubmitContext } from '../../../../contexts/ProposalSubmitContext';
import ProposalSubmit from './ProposalSubmit';
import PlannedDocuments from './PlannedDocuments';
import { useOpenedTypeDispatch } from '../../../../contexts/OpenedTypeContext';
import GeoProductList from './GeoProductList';
import { GeoProduct } from './GeoProduct';
import useSessionStorage from '../../../../utils/useSessionStorage';
import { usePlannedDocumentProposal } from '../../../../contexts/PlannedDocumentProposalContext';
import { useApplyForNotifications } from '../../../../contexts/ApplyForNotificationsContext';
import { ApplyForNotificationSideBarWrapper } from './styles';

interface SidebarsProps {
  setIsOpenPlannedDocuments: Dispatch<SetStateAction<boolean>>;
  isOpenPlannedDocuments: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

const Sidebars = ({ isOpenPlannedDocuments, setIsOpenPlannedDocuments, setIsOpen, isOpen }: SidebarsProps) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [isOpenGeoProduct, setIsOpenGeoproduct] = useState<boolean>(false);
  const [selectedGeoProduct, setSelectedGeoProduct] = useState<number | null>(null);
  const {
    isOpen: isOpenNotification,
    setIsOpen: setIsOpenNotification,
    setCoords,
    setAddress: setMapAddress,
  } = useContext(NotificationContext);
  const [isOpenDetailPlannedDocument, setIsOpenDetailPlannedDocument] = useState<boolean>(false);
  const [selectedPlannedDocument, setSelectedPlannedDocument] = useState<number | null>(null);
  const [selectedGeoProductTitle, setSelectedGeoProductTitle] = useState('');
  const [selectedNotification, setSelectedNotification] = useState<number | null>(null);
  const [isOpenUserNotification, setIsOpenUserNotification] = useState<boolean>(false);
  const [isZoomToPlannedDocument, setIsZoomToPlannedDocument] = useState<boolean>(true);
  const [selectedPlannedDocumentTitle, setSelectedPlannedDocumentTitle] = useState<string>('');
  const [plannedDocumentMunicipality, setPlannedDocumentMunicipality] = useState<string>('');

  const { hash } = useLocation();
  const intl = useIntl();
  const location = useLocation();
  const notificationId = searchParams.get('notification');
  const navigate = useNavigate();
  const openedTypeDispatch = useOpenedTypeDispatch();
  const { setSessionValue: setGeoProductIdInSession, removeSessionValue: removeGeoProductIdFromSession } =
    useSessionStorage('GEO_PRODUCT_ID');
  const { setSessionValue: setGeoOrderIdInSession, removeSessionValue: removeGeoOrderIdFromSession } =
    useSessionStorage('GEO_ORDER_ID');
  const { removeFromSession: removeProposalFromSession } = usePlannedDocumentProposal();
  const { removeFromSession: removeApplyForNotificationsFromSession } = useApplyForNotifications();
  const { value: OrderModalIsOpen } = useSessionStorage('ORDER_CONFIRMATION');

  const {
    isOpen: isProposalSubmitOpen,
    duration,
    toggleHandler: ProposalSubmitHandler,
  } = useContext(ProposalSubmitContext);

  useEffect(() => {
    if (searchParams.get('notification')) {
      setSelectedNotification(parseInt(searchParams.get('notification') || ''));
      setIsOpenUserNotification(true);
      setIsOpenNotification(true);
    }
  }, [searchParams]);

  const closeUserNotification = () => {
    setIsOpenUserNotification(false);
    clearSearchParams();
    setIsOpenNotification(false);
    setCoords(undefined);
    setMapAddress(undefined);
    removeApplyForNotificationsFromSession();

    if (notificationId !== 'open') {
      navigate('/user-notifications');
    }
  };

  useEffect(() => {
    if (selectedNotification) {
      setIsOpenUserNotification(true);
    }
  }, [selectedNotification]);

  useEffect(() => {
    const geoProductId = searchParams.get('geoProductId');
    const geoOrderId = searchParams.get('geoOrderId');

    if (geoOrderId) {
      setGeoOrderIdInSession(geoOrderId);
    } else {
      removeGeoOrderIdFromSession();
    }

    if (geoProductId) {
      setSelectedGeoProduct(parseInt(geoProductId || ''));
      setGeoProductIdInSession(geoProductId);
      setIsOpenGeoproduct(true);
    } else if (!OrderModalIsOpen) {
      setIsOpenGeoproduct(false);
      setSelectedGeoProduct(null);
      removeGeoProductIdFromSession();
    }
  }, [searchParams]);

  useEffect(() => {
    if (hash) {
      setIsZoomToPlannedDocument(!(hash.indexOf('nozoom') > -1));
      setSelectedPlannedDocument(parseInt(hash.replace('#document_', '')));

      if (isProposalSubmitOpen) {
        onProposalSubmitDrawerClose();
      }
    } else {
      setSelectedPlannedDocument(null);
      setIsOpenDetailPlannedDocument(false);
      openedTypeDispatch({ type: 'UNSELECT_TAPIS_DOCUMENT' });
      removeProposalFromSession();
    }
  }, [hash]);

  const clearSearchParams = () => {
    location.search = '';
    navigate(location.pathname);
  };

  const closePlannedDocuments = () => {
    openedTypeDispatch({ type: 'CLOSE_DOCUMENT' });

    const existingHash = location.hash;

    navigate(location.pathname + existingHash);
  };

  const closeGeoProducts = () => {
    openedTypeDispatch({ type: 'CLOSE_DOCUMENT' });
    setIsOpen(false);
    setSearchParams((params) => {
      params.set('geoproduct', 'false');
      return params;
    });
  };

  // show detailDocument when select it from List
  useEffect(() => {
    if (selectedPlannedDocument) {
      setIsOpenDetailPlannedDocument(true);
    }
  }, [selectedPlannedDocument]);

  // show detailDocument when select it from List
  useEffect(() => {
    if (selectedGeoProduct) {
      setIsOpenGeoproduct(true);
    }
  }, [selectedGeoProduct]);

  useEffect(() => {
    setIsOpen(false);
    setIsOpenPlannedDocuments(false);
  }, [isOpenNotification]);

  const onProposalSubmitDrawerClose = () => {
    ProposalSubmitHandler();
  };

  const onPlannedDocumentSelect = (id: number) => {
    setSelectedPlannedDocument(id);
    navigate(`/geo/tapis?documents=open#document_${id}`);
  };

  const onGeoproductSelect = (id: number) => {
    setSelectedGeoProduct(id);
    setGeoProductIdInSession(String(id));
    navigate(`/main?geoproduct=open&geoProductId=${id}`);
  };

  const handlePlannedDocumentTitle = (documentTitle: string) => {
    setSelectedPlannedDocumentTitle(documentTitle);
  };

  const handlePlannedDocumentMunicipality = (documentMunicipality: string) => {
    setPlannedDocumentMunicipality(documentMunicipality);
  };

  return (
    <>
      <ApplyForNotificationSideBarWrapper>
        <SidebarDrawer
          title={intl.formatMessage({ id: 'notification.application_for_notification' })}
          subtitle={
            <div className="mt-3">
              <i>{intl.formatMessage({ id: 'notification.intend_to_apply' })}</i>
            </div>
          }
          isOpen={isOpenUserNotification}
          onClose={closeUserNotification}
        >
          {isOpenUserNotification && <ApplyingForNotification onClose={closeUserNotification} />}
        </SidebarDrawer>
      </ApplyForNotificationSideBarWrapper>
      <SidebarDrawer
        title={intl.formatMessage({ id: 'planned_documents.documents' })}
        isOpen={isOpenPlannedDocuments}
        onClose={closePlannedDocuments}
      >
        {isOpenPlannedDocuments && (
          <PlannedDocuments
            isOpenDocument={isOpenDetailPlannedDocument}
            setSelectedPlannedDocument={(id: number) => onPlannedDocumentSelect(id)}
          />
        )}
      </SidebarDrawer>
      <SidebarDrawer
        title={selectedPlannedDocumentTitle}
        subtitle={plannedDocumentMunicipality}
        isOpen={isOpenDetailPlannedDocument}
        onClose={() => {
          setIsOpenDetailPlannedDocument(false);
          openedTypeDispatch({ type: 'UNSELECT_TAPIS_DOCUMENT' });
          setSelectedPlannedDocument(null);
          removeProposalFromSession();
          navigate('/main?documents=open');
        }}
      >
        {selectedPlannedDocument && (
          <DetailedPlannedDocument
            documentTitle={handlePlannedDocumentTitle}
            documentMunicipality={handlePlannedDocumentMunicipality}
            id={selectedPlannedDocument}
            isZoomToPlannedDocument={isZoomToPlannedDocument}
          />
        )}
      </SidebarDrawer>
      <SidebarDrawer title="Ģeoproduktu katalogs" isOpen={isOpen} onClose={closeGeoProducts}>
        {isOpen && (
          <GeoProductList setSelectedGeoProduct={onGeoproductSelect} setSelectedTitle={setSelectedGeoProductTitle} />
        )}
      </SidebarDrawer>
      <SidebarDrawer
        title={selectedGeoProductTitle}
        isOpen={isOpenGeoProduct}
        onClose={() => {
          setIsOpenGeoproduct(false);
          setSelectedGeoProduct(null);
          removeGeoProductIdFromSession();
          navigate('/main?geoproduct=open');
        }}
      >
        {selectedGeoProduct && <GeoProduct id={selectedGeoProduct} setSelectedTitle={setSelectedGeoProductTitle} />}
      </SidebarDrawer>
      <SidebarDrawer
        title={intl.formatMessage({ id: 'proposal.submit' })}
        isOpen={isProposalSubmitOpen}
        onClose={onProposalSubmitDrawerClose}
      >
        <ProposalSubmit duration={duration} />
      </SidebarDrawer>
    </>
  );
};

export default Sidebars;
